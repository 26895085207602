<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="Khoa/bộ môn"
                label-for="input-default"
              >
                <v-select
                  v-model="departmentSelected"
                  :options="departments"
                  @input="onChangeDepartments"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="text-right">
                <b-button
                  variant="primary"
                  @click="btnSearchClick()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="align-text-top"
                  />
                  Tìm kiếm
                </b-button>
                <b-button
                  v-if="creatable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.teacherTeamSaveModal
                  variant="success"
                  class="ml-md-1 mt-1 mt-md-0"
                  @click="onCreateTeacherTeam"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon" /> Thêm mới
                  </span>
                </b-button>
              </div>
            </b-col>

            <!-- Per Page -->
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                class="my-table"
                style-class="vgt-table striped bordered research-table"
                row-style-class="vgt-row"
                :columns="columns"
                :rows="teacherTeamsDisplay"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                line-numbers
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.teacherTeamSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditTeacherTeam(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteTeacherTeam(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb- mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TeacherTeamSave
      ref="saveModalRef"
      :item="currentTeacherTeam"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import TeacherTeamSave from '@/views/admin/category/teacherTeam/TeacherTeamSave.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'TeacherTeam',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
    TeacherTeamSave,
  },
  data() {
    return {
      departmentSelected: undefined,
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        departmentId: null,
        leaderId: null,
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      teacherTeamsDisplay: [],
      currentTeacherTeam: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã tổ bộ môn',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên tổ bộ môn',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tổ trưởng',
          field: 'leaderName',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: this.statuses,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teacherTeams: 'teacherTeam/teacherTeams',
      totalRows: 'teacherTeam/totalRows',
      statuses: 'teacherTeam/statuses',
      departments: 'dropdown/departments',
    }),
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TEACHER_TEAM)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TEACHER_TEAM)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TEACHER_TEAM)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getDepartment({ organizationId: getUser().orgId }),
        this.btnSearchClick()
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
      const statusesCV = this.statuses.map(item => ({ value: item.value, text: item.label }))
      this.$set(this.columns[3].filterOptions, 'filterDropdownItems', statusesCV)
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readTeacherTeams: 'teacherTeam/readTeacherTeams',
      updateTeacherTeams: 'teacherTeam/updateTeacherTeams',
      deleteTeacherTeams: 'teacherTeam/deleteTeacherTeams',
      getDepartment: 'dropdown/getDepartments',
    }),
    isSystemAdmin,
    onChangeDepartments() {
      // this.filter.organizationId = this.organizationSelected.id
    },
    async btnSearchClick() {
      this.isLoading = true
      if (this.departmentSelected !== null && this.departmentSelected !== undefined) {
        this.filter.departmentId = this.departmentSelected.value
      } else {
        this.filter.departmentId = null
      }
      try {
        await Promise.all([
          this.readTeacherTeams(this.filter),
        ])
        this.teacherTeamsDisplay = this.teacherTeams
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreateTeacherTeam() {
      this.currentTeacherTeam = undefined
    },
    onEditTeacherTeam(teacherTeam) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = teacherTeam
      this.currentTeacherTeam = rest
    },
    onDeleteTeacherTeam(teacherTeam) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa tổ bộ môn <span class="text-danger">${teacherTeam.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTeacherTeams(teacherTeam.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readTeacherTeams(this.filter)
                  this.teacherTeamsDisplay = this.teacherTeams
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeacherTeamsFromStore()
    },
    async getTeacherTeamsFromStore() {
      this.isLoading = true
      try {
        await this.readTeacherTeams(this.filter)
        this.teacherTeamsDisplay = this.teacherTeams
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getTeacherTeamsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else if (field === 'orderNo') this.updateParams({ sort: `3_${type}` })
        else this.updateParams({ sort: `4_${type}` })
      }
      await this.getTeacherTeamsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getTeacherTeamsFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getTeacherTeamsFromStore()
    },
  },
}
</script>

<style scoped>

</style>
